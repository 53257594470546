@font-face {
  font-family: 'Roobert-Regular';
  src: local('Roobert-Regular'), url('./assets/fonts/Roobert-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roobert-RegularItalic';
  src: local('Roobert-RegularItalic'),
    url('./assets/fonts/Roobert-RegularItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roobert-SemiBold';
  src: local('Roobert-SemiBold'), url('./assets/fonts/Roobert-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roobert-SemiBoldItalic';
  src: local('Roobert-SemiBoldItalic'),
    url('./assets/fonts/Roobert-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Trust1A-Medium';
  src: local('Trust1A-Medium'), url('./assets/fonts/Trust1A-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Trust1A-MediumItalic';
  src: local('Trust1A-MediumItalic'),
    url('./assets/fonts/Trust1A-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Trust1A-Regular';
  src: local('Trust1A-Regular'), url('./assets/fonts/Trust1A-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Trust1A-RegularItalic';
  src: local('Trust1A-RegularItalic'),
    url('./assets/fonts/Trust1A-RegularItalic.ttf') format('truetype');
}
